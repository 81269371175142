import React from 'react';
import {graphql} from "gatsby";
import Layout from '../../components/theme/Layout'
import Header from '../../components/theme/Header'
import PetsPage from '../../components/pages/PetsPage';


const Pets = ({data}) => (
  <Layout
    meta={{
      title: 'Home for Every Pet Project | ROYALE®'
    }}
    urls={{
      'en': `/pets`,
      'fr': `/fr/ami`
    }}
    locale='en'
    ogimage="https://royaleapi.irvingconsumerproducts.com/globalassets/promotion/pets/HFEPP-en-share.jpg"
  >
    <Header
      urls={{
        'en': `/pets`,
        'fr': `/fr/ami`
      }}
      locale='en'
    />
    <PetsPage blogPosts={data.prismic} locale='en' />
  </Layout>
)

export const query = graphql`
{
  prismic {
    blog_1: home_solution_article(uid: "a-natural-way-to-keep-your-furry-friends-ears-clean", lang: "en-ca") {
      title
      preview_image
      _meta {
        id
        uid
      }
    }
    blog_2: home_solution_article(uid: "a-pet-owner-hack-perfect-for-shedding-season", lang: "en-ca") {
      title
      preview_image
      _meta {
        id
        uid
      }
    }
    blog_3: home_solution_article(uid: "stop-pet-mess-in-its-tracks", lang: "en-ca") {
      title
      preview_image
      _meta {
        id
        uid
      }
    }
  }
}
`

export default Pets
